import { ObjectWithAnyProps } from 'interfaces/generalTypes'

/**
 * We split our frontend bundle into multiple packs that can be downloaded separately.
 * Unfortunately, this means that vite cannot reliably ensure that required (or imported) files
 * will only be evaluated once. If it is crucial for an object to have only one instance across the entire
 * page, we can use this function which caches it in the global window object.
 * @param name A unique identifier, such as the class name
 * @param factory A function that returns the object to be cached
 * @returns The single instance of that object
 */
export default function singleton<T>(name: string, factory: () => T): T {
  (window as ObjectWithAnyProps).tlSingletons = (window as ObjectWithAnyProps).tlSingletons || {}
  const singletons = (window as ObjectWithAnyProps).tlSingletons as Record<string, unknown>

  if (singletons[name] == null) {
    singletons[name] = factory()
  }
  return singletons[name] as T
}
